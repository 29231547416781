/*
 Set tooltip on disabled menu links & disable anchor
 */
$('.main-sidebar .sidebar-menu li.disabled').tooltip({
    title: "Coming soon!",
    placement: "right",
    delay: { "show": 700, "hide": 100 }
}).find('a').removeAttr('href');

/*
 Export
 */

// select all in same group 
$('.field-list').find('.all').click(function (event) {
    var status = $(event.currentTarget).is(':checked');
    var group = $(event.currentTarget).attr('class').split(' ')[1]
    $(".field-list input[name^='"+group+"']").not(".input-disabled").prop('checked', status);
});

$('#exportSelectAll').click(function (event) {
    event.preventDefault();
    $('.field-list input').not(".input-disabled").prop('checked', true);
});

$('#exportDeselectAll').click(function (event) {
    event.preventDefault();
    $('.field-list input').not(".input-disabled").prop('checked', false);
});

$('#enablingTemplate').click(function (event) {
    event.preventDefault();

    // disable all
    $('.field-list input').not(".input-disabled").prop('checked', false);

    // enabled the following
    $(".field-list input[name='gs1[article_number]'], .field-list input[name='sa24[status]']").prop('checked', true);
});
$('#pricingTemplate').click(function (event) {
    event.preventDefault();
    // disable all
    $('.field-list input').not(".input-disabled").prop('checked', false);

    // enabled the following
    $(".field-list input[name='gs1[article_number]'], .field-list input[name='sa24[price]']").prop('checked', true);

    //set value input hidden
    $('#checked').val('pricing');
});
$('#logisticalTemplate').click(function (event) {
    event.preventDefault();
    // disable all
    $('.field-list input').not(".input-disabled").prop('checked', false);

    // enabled the following
    $(".field-list input[name='gs1[article_number]'], .field-list input[name='sa24[reorder_point]'], " +
        ".field-list input[name='sa24[notification_days]'], .field-list input[name='sa24[min_stock]'], " +
        ".field-list input[name='sa24[lead_time]']").prop('checked', true);

    //set value input hidden
    $('#checked').val('logistical');
});






// Cache selectors outside callback for performance.
var $window = $(window),
    $stickyEl = $('.sticky');
if($stickyEl.length > 0){
    var elTop = $stickyEl.offset().top;
}


$window.scroll(function() {
    if($stickyEl.length > 0){
        $stickyEl.toggleClass('fixed', $window.scrollTop() > elTop);
    }

});

/*
 * Edit Product
 *
 */
// $("#dtBox").DateTimePicker({
//     // TODO Create language file for this
//     shortDayNames: ["Søn", "Man", "Tir", "Ons", "Tor", "Fre", "Lør"],
//     fullDayNames: ["Søndag", "Mandag", "Tirsdag", "Onsdag", "Torsdag", "Fredag", "Lørdag"],
//     shortMonthNames: ["Jan", "Feb", "Mar", "Apr", "Maj", "Jun", "Jul", "Aug", "Sep", "Okt", "Nov", "Dec"],
//     fullMonthNames: ["Januar", "Februar", "Marts", "April", "Maj", "Juni", "Juli", "August", "September", "Oktober", "November", "December"],
//     titleContentDate: "Sæt Dato",
//     titleContentTime: "Sæt Tid",
//     titleContentDateTime: "Sæt Dato & Tid",
//     setButtonContent: "Sæt",
//     clearButtonContent: "Annullere",
//
//     // minuteInterval: 15
// });



$('input[name="sa24[special_from_date]"], input[name="sa24[special_to_date]"]').daterangepicker({
    locale: {
        format: 'DD-MM-YYYY'
    },
    autoUpdateInput: false,
    singleDatePicker: true,
    showDropdowns: true
});
$('input[name="sa24[special_from_date]"], input[name="sa24[special_to_date]"]').on('apply.daterangepicker', function(ev, picker) {
    $(this).val(picker.startDate.format('DD-MM-YYYY'));
});

$('input[name="sa24[special_from_date]"], input[name="sa24[special_to_date]"]').on('cancel.daterangepicker', function(ev, picker) {
    $(this).val('');
});